<template>
  <b-message type="is-dark">
    <p class="has-margin-bottom-100">
      <b-tag type="is-dark">Please note</b-tag>
      When new site tasks are created, delegates are
      <strong>NOT</strong> automatically granted access to those tasks – instead
      you must manually grant access on a case-by-case basis. If you wish to
      change this default behaviour and auto-assign access for all site users,
      you can do so here.
    </p>
    <b-switch
      :value="autoAssignDelegatesToTasks"
      :disabled="isProcessing"
      @input="saveDetails"
      >Auto-assign delegates to new tasks:
      <strong>{{ autoAssignDelegatesToTasks ? "Yes" : "No" }}</strong>
    </b-switch>
  </b-message>
</template>
<script>
export default {
  name: "AutoAssignDelegatesToTasksField",
  props: {
    siteId: {
      required: true,
      type: String
    },
    value: {
      required: true,
      type: Boolean
    }
  },
  data() {
    return {
      isProcessing: false,
      autoAssignDelegatesToTasks: this.value
    };
  },
  watch: {
    value(value) {
      this.autoAssignDelegatesToTasks = value;
    }
  },
  methods: {
    saveDetails(value) {
      if (!this.isProcessing) {
        this.isProcessing = true;
        this.$store
          .dispatch("sites/updateSite", {
            siteId: this.siteId,
            payload: { autoAssignDelegatesToTasks: value }
          })
          .then(() => {
            this.$toast.open({
              message: "Site details updated"
            });
          })
          .catch(error => {
            this.$toast.open({
              message: error.message,
              type: "is-danger"
            });
          })
          .finally(() => {
            this.isProcessing = false;
          });
      }
    }
  }
};
</script>
